import './Halara.css';


const Halara = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>Halara</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='onix-box'>
                    <h2 style={{textAlign: 'left'}}>
        Halara is an innovative athleisure brand that infuses activewear into everyday apparel, made for what moves you. We apply performance fabric and functionality to designs that adapt to every aspect of your life. Powered by an innovative production model that minimizes waste, we update our styles, fabrics, colors, and even lengths based on your feedback. 
        Use the code below to get your discount!
        <br />
        <br />
        Code: callie15off for 15% of non discounted items
        <br />
        Code: callie5off for 5% of discounted items
                    </h2>
                    <p>

                    </p>
                    <div className='onix-button'>
                    {/*<a href="https://www.aloemd.com/?ref=0NOcKe1Psbc64-" target="_blank" ><button className='clinic_button'>Shop AloeMD Products</button></a>*/}
                    <a href="http://halara.link/s/13zMsCj0dd" target="_blank"><button className='clinic_button'>Use Callie's Discount</button></a>
                    </div>                    
                </div>                         
            </div> 
            <br />      
        </div>
    )
}

export default Halara;